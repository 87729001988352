export const colorWords = [
  'absolute zero',
  'aero',
  'alabaster',
  'alizarin',
  'almond',
  'amaranth',
  'amazon',
  'amber',
  'amethyst',
  'antique brass',
  'apricot',
  'aqua',
  'aureolin',
  'azure',
  'baby powder',
  'banana mania',
  'beaver',
  'beige',
  'bisque',
  'bistre',
  'bittersweet',
  'black',
  'bleu de france',
  'blond',
  'blue',
  'blush',
  'bole',
  'bone',
  'brandy',
  'bronze',
  'brown',
  'buff',
  'burgundy',
  'burlywood',
  'byzantine',
  'byzantium',
  'cadet',
  'café au lait',
  'café noir',
  'camel',
  'canary',
  'capri',
  'caput mortuum',
  'cardinal',
  'carmine',
  'carnelian',
  'catawba',
  'cedar chest',
  'celadon',
  'celeste',
  'cerise',
  'cerulean',
  'champagne',
  'charcoal',
  'chartreuse',
  'chestnut',
  'chocolate',
  'cinereous',
  'cinnabar',
  'cinnamon satin',
  'citrine',
  'citron',
  'claret',
  'coffee',
  'copper',
  'coquelicot',
  'coral',
  'cordovan',
  'corn',
  'cosmic cobalt',
  'cosmic latte',
  'cotton candy',
  'cream',
  'crimson',
  'crystal',
  'cyan',
  'cyber grape',
  'cyclamen',
  'deep space sparkle',
  'denim',
  'desert',
  'drab',
  'ebony',
  'ecru',
  'eggplant',
  'eggshell',
  'eigengrau',
  'emerald',
  'eminence',
  'erin',
  'fallow',
  'fandango',
  'fawn',
  'feijoa',
  'feldgrau',
  'finn',
  'firebrick',
  'flame',
  'flax',
  'flirt',
  'frostbite',
  'fuchsia',
  'fulvous',
  'fuzzy wuzzy',
  'gainsboro',
  'gamboge',
  'glaucous',
  'glossy grape',
  'gold',
  'granny smith apple',
  'gray',
  'green',
  'grenadier',
  'grey',
  'grullo',
  'gunmetal',
  'harlequin',
  'heat wave',
  'heliotrope',
  'honeydew',
  'iceberg',
  'inchworm',
  'independence',
  'indigo',
  'iris',
  'irresistible',
  'isabelline',
  'ivory',
  'jade',
  'jasmine',
  'jazzberry jam',
  'jet',
  'jonquil',
  'june bud',
  'keppel',
  'khaki',
  'kobe',
  'kobi',
  'lapis lazuli',
  'lava',
  'lavender',
  'lemon',
  'liberty',
  'lilac',
  'lime',
  'linen',
  'lion',
  'liver',
  'livid',
  'macaroni and cheese',
  'madder lake',
  'magenta',
  'magnolia',
  'mahogany',
  'maize',
  'malachite',
  'manatee',
  'mandarin',
  'mango',
  'mantis',
  'mardi gras',
  'maroon',
  'mauve',
  'melon',
  'metallic seaweed',
  'metallic sunburst',
  'midnight',
  'mindaro',
  'ming',
  'mint',
  'misty moss',
  'moccasin',
  'mona lisa',
  'mountain meadow',
  'mulberry',
  'mustard',
  'mystic',
  'navy',
  'neon carrot',
  'nickel',
  'nyanza',
  'ochre',
  'old lace',
  'oldlace',
  'olive',
  'olivine',
  'onyx',
  'opal',
  'orange',
  'orchid',
  'outer space',
  'oxblood',
  'pale dogwood',
  'papaya whip',
  'papayawhip',
  'parchment',
  'patriarch',
  'paua',
  'peach',
  'pear',
  'periwinkle',
  'permanent geranium lake',
  'persimmon',
  'peru',
  'phlox',
  'pine tree',
  'pink',
  'pistachio',
  'platinum',
  'plum',
  'polished pine',
  'pomp and power',
  'popstar',
  'prune',
  'puce',
  'pumpkin',
  'purple',
  'purpureus',
  'rajah',
  'raspberry',
  'razzmatazz',
  'razzmic berry',
  'red',
  'rhythm',
  'rocket metallic',
  'rose',
  'rosso corsa',
  'ruber',
  'ruby',
  'rufous',
  'russet',
  'rust',
  'saffron',
  'sage',
  'salmon',
  'sand',
  'sapphire',
  'scarlet',
  'seance',
  'seashell',
  'secret',
  'sepia',
  'shadow',
  'shiny shamrock',
  'sienna',
  'silver',
  'sinopia',
  'sizzling sunrise',
  'smitten',
  'snow',
  'spring bud',
  'spring frost',
  'straw',
  'sunglow',
  'sunray',
  'sunset',
  'tan',
  'taupe',
  'teal',
  'tenné',
  'terra cotta',
  'thistle',
  'timberwolf',
  'tomato',
  'tourmaline',
  'tropical rainforest',
  'tumbleweed',
  'turquoise',
  'tuscan',
  'ultramarine',
  'umber',
  'unbleached silk',
  'vanilla',
  'verdigris',
  'veronica',
  'violet',
  'viridian',
  'volt',
  'wheat',
  'white',
  'wine',
  'winter sky',
  'wisteria',
  'xanadu',
  'xanthic',
  'xanthous',
  'yellow',
  'zaffre',
  'zomp',
];

export const ickyWords = [
  'blog',
  'curd',
  'fester',
  'fetus',
  'gurgle',
  'lugubrious',
  'moist',
  'mucus',
  'ooze',
  'orifice',
  'phlegm',
  'pus',
  'seepage',
  'splooge',
  'yolk',
];

export const dogNameWords = [
  'abby',
  'ace',
  'allie',
  'angel',
  'annie',
  'apollo',
  'archie',
  'arlo',
  'aspen',
  'athena',
  'atlas',
  'ava',
  'axel',
  'babe',
  'baby',
  'bailey',
  'balto',
  'bandit',
  'baxter',
  'bear',
  'beau',
  'beethoven',
  'bella',
  'belle',
  'benji',
  'benny',
  'bentley',
  'blaze',
  'blue',
  'bo',
  'bobbie',
  'bonnie',
  'boomer',
  'bowie',
  'brady',
  'brandy',
  'brody',
  'bruce',
  'bruno',
  'brutus',
  'bubba',
  'bud',
  'buddy',
  'buster',
  'cali',
  'callie',
  'casey',
  'cash',
  'champ',
  'chance',
  'chandler',
  'charlie',
  'chase',
  'chester',
  'chewy',
  'chico',
  'chief',
  'chip',
  'chloe',
  'cleo',
  'cliff',
  'clifford',
  'coco',
  'cocoa',
  'cody',
  'cookie',
  'cooper',
  'copper',
  'cosmo',
  'daisy',
  'dakota',
  'dash',
  'delilah',
  'dexter',
  'diesel',
  'dixie',
  'dolly',
  'dozer',
  'duke',
  'echo',
  'ella',
  'ellie',
  'elvis',
  'emma',
  'fido',
  'finn',
  'fiona',
  'frank',
  'frankie',
  'george',
  'georgia',
  'ghost',
  'gigi',
  'ginger',
  'gizmo',
  'goose',
  'grace',
  'gracie',
  'gucci',
  'gunner',
  'gunther',
  'gus',
  'hank',
  'hannah',
  'harley',
  'harper',
  'harry',
  'hazel',
  'heidi',
  'henry',
  'holly',
  'honey',
  'hunter',
  'ivy',
  'izzy',
  'jack',
  'jackson',
  'jake',
  'jasmine',
  'jasper',
  'jax',
  'joey',
  'jofi',
  'josie',
  'juno',
  'kai',
  'katie',
  'king',
  'kobe',
  'koda',
  'kona',
  'kylo',
  'lacey',
  'lady',
  'lassie',
  'layla',
  'leia',
  'leo',
  'levi',
  'lexi',
  'lexie',
  'lilly',
  'lily',
  'lincoln',
  'loki',
  'lola',
  'louie',
  'louis',
  'lucky',
  'lucy',
  'luke',
  'lulu',
  'luna',
  'mac',
  'macy',
  'maddie',
  'madison',
  'maggie',
  'marley',
  'maverick',
  'max',
  'maya',
  'mia',
  'mickey',
  'mila',
  'miles',
  'millie',
  'milo',
  'mimi',
  'minnie',
  'missy',
  'misty',
  'mocha',
  'molly',
  'moose',
  'murphey',
  'murphy',
  'nala',
  'nemo',
  'nikki',
  'nova',
  'oakley',
  'odie',
  'odin',
  'olive',
  'oliver',
  'ollie',
  'oreo',
  'oscar',
  'otis',
  'ozzy',
  'paisley',
  'peanut',
  'pearl',
  'pebbles',
  'penelope',
  'penny',
  'pepper',
  'phoebe',
  'piper',
  'poppy',
  'porter',
  'prince',
  'princess',
  'rambo',
  'ranger',
  'remi',
  'remy',
  'rex',
  'riley',
  'rin tin tin',
  'rocco',
  'rocket',
  'rocky',
  'romeo',
  'roscoe',
  'rose',
  'rosie',
  'ross',
  'roxi',
  'roxie',
  'roxy',
  'ruby',
  'rudy',
  'rufus',
  'ruger',
  'rusty',
  'ryder',
  'sadie',
  'sally',
  'sam',
  'sammy',
  'samson',
  'sandy',
  'sarge',
  'sasha',
  'sassy',
  'scooby',
  'scooter',
  'scout',
  'shadow',
  'shelby',
  'sierra',
  'simba',
  'skye',
  'slinky',
  'sophie',
  'sparky',
  'spike',
  'spot',
  'stella',
  'sugar',
  'sydney',
  'tank',
  'teddy',
  'theo',
  'thor',
  'tiny',
  'titan',
  'toby',
  'toto',
  'toulouse',
  'trakr',
  'trixie',
  'tucker',
  'tyson',
  'wally',
  'walter',
  'watson',
  'whiskey',
  'willow',
  'winnie',
  'winston',
  'xena',
  'zeke',
  'zeus',
  'ziggy',
  'zoe',
  'zoey ',
  'zoey',
];

export const swearWords = [
  'fuck',
  'shit',
  'damn',
  'cunt',
  'hell',
  'piss',
  'jizz',
  'tits',
  'twat',
  'cock',
  'dick',
  'crap',
  'arse',
];

export const censoredSwearWords = swearWords.map((w) => `${w[0]}**${w[3]}`);
